<template>
  <v-navigation-drawer
    id="hb-navigation"
    class="pa-0 custom-navigation-drawer"
    :width="60"
    clipped
    disable-resize-watcher
    hide-overlay
    app
    elevation-1
    mobile-breakpoint="1"
    flat
    :style="{
      transform: $vuetify.breakpoint.xs ? (drawer ? 'translateX(0%)' : 'translateX(-100%) !important') : ''
    }"
  >
    <v-layout
      :column="$vuetify.breakpoint.smAndUp || $vuetify.breakpoint.xs"
      fill-height
    >
      <v-list
        
        class="mt-sm-1"
        
        style="padding-left: 9px;"
        dense
      >
        <!--
        <v-list-item v-for="(item, index) in headerNavLinks" :key="index" >
            <nav-link :item="item" />
        </v-list-item>
        -->

        <!-- Turn back on for home button -->
        <!--
        <v-list-item class="pl-3 my-sm-1" :class="{ 'pl-3': !$vuetify.breakpoint.xs }">
          <v-btn
            color="#677380"
            icon
            medium
            to="/dashboard"
          >
            <v-icon>
              mdi-home
            </v-icon>
          </v-btn>
        </v-list-item>
        -->

        <v-list-item class="pl-0 pr-1 my-sm-1" :class="{ 'pl-0' : $vuetify.breakpoint.xs }" :style="$vuetify.breakpoint.xs ? 'padding-top:3px;' : ''">
          <hb-btn
            icon
            tooltip="Search"
            active-state-off
            @click="goSearch"
            :disabled="!isHBUser"
          >
            mdi-magnify
          </hb-btn>
        </v-list-item>

        <v-list-item class="pl-0 pr-1 my-sm-1" v-show="!$vuetify.breakpoint.xs">
          <hb-btn
            icon
            tooltip="Quick Actions"
            active-state-off
            @click="goQuickLinks"
          >
            mdi-flash-circle
          </hb-btn>
        </v-list-item>
        <!--
        <v-list-item v-if="isAdmin" class="pl-0 pr-1 my-sm-1">
            <notifications-list></notifications-list>
        </v-list-item>
        -->
        <v-list-item v-if="isAdmin" class="pl-0 pr-1 my-sm-1">
            <hb-btn
              icon
              tooltip="Create Task"
              :active="todo"
              @click="todo = true"
              :disabled="!isHBUser"
            >mdi-calendar-today</hb-btn>
            <todo v-model="todo" v-if="todo" @close="closeCreateTask"></todo>
        </v-list-item>

<!--        <v-list-item class="pl-0 pr-1 my-sm-1">-->
<!--          <hb-btn-->
<!--            icon-->
<!--            tooltip="Collection Center"-->
<!--            active-state-off-->
<!--            @click="goDelinquencyCenter"-->
<!--          >-->
<!--            mdi-cash-usd-outline-->
<!--          </hb-btn>-->
<!--        </v-list-item>-->
<!--        <v-list-item class="pl-0 pr-1 my-sm-1">-->
<!--          <hb-btn-->
<!--            icon-->
<!--            tooltip="Lead Follow Up"-->
<!--            active-state-off-->
<!--            @click="goLeadFollowUp"-->
<!--          >-->
<!--            mdi-account-plus-->
<!--          </hb-btn>-->
<!--        </v-list-item>-->

        <v-list-item class="pl-0 pr-1 my-sm-1">
            <hb-btn
              type="icon"
              tooltip="Tasks"
              active-state-off
              @click="goTaskCenter"
              :disabled="!isHBUser"
            >
              <template slot-scope="{ hover }">
                <v-badge
                  :value="(tasksCounter && tasksCounter > 0) || showTasksIndicator"
                  color="#F26500"
                  overlap
                  dot
                  offset-x="8"
                >
                  <hb-icon
                    :hover="hover"
                  >mdi-playlist-check</hb-icon>
                </v-badge>
              </template>
            </hb-btn>
        </v-list-item>

        <v-list-item class="pl-0 pr-1 my-sm-1">
            <hb-btn
              type="icon"
              tooltip="Messages"
              active-state-off
              @click="goCommunicationCenter"
              :disabled="!isHBUser"
            >
              <template slot-scope="{ hover }">
                <v-badge
                  v-if="messageCount"
                  :content="messageCount > 99 ? '99+' : messageCount"
                  :value="true"
                  color="#F26500"
                  overlap
                  offset-x="18"
                  offset-y="6"
                  >
                  <hb-icon :hover="hover">mdi-forum</hb-icon>
                </v-badge>
                <hb-icon v-else :hover="hover">mdi-forum</hb-icon>
              </template>
            </hb-btn>
        </v-list-item>

        <v-list-item v-if="$vuetify.breakpoint.xs" class="pr-3 mb-sm-4 mt-sm-2" :style="$vuetify.breakpoint.xs ? 'padding-left:6px;' : 'padding-left:14px;'">
          <v-avatar color="#101318" size="32">
            <span class="white--text" style="font-size:18px;">{{contact.first.substring(0, 1).toUpperCase()}}</span>
          </v-avatar>
        </v-list-item>

      </v-list>
      <v-spacer></v-spacer> 
      <v-list
        :row="$vuetify.breakpoint.xs"
        :wrap="$vuetify.breakpoint.xs"
        dense
        v-if="!$vuetify.breakpoint.xs"
      >
      <v-list-item 
          v-show="hasPlatformApps"
          class="my-sm-1 pr-1"
          :style="'padding-left:9px;'"
        >
          <v-menu
            v-model="showLauncher"
            center
            offset-y
            transition="slide-x-transition"
            nudge-top="700"
            nudge-width="336"
            max-width="100%"
            content-class="platformApp"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{on,attrs}">
              <hb-tooltip>
                <template v-slot:item>
                  <v-btn
                    type="icon"
                    tooltip="Applications"
                    class="app-launcher-icon-btn"
                    active-state-off
                    plain
                    depressed
                    v-on="on"
                    v-bind="attrs"
                    @click="launcherDrawer = !launcherDrawer"
                  >
                    <hb-icon>mdi-apps</hb-icon>
                  </v-btn>
                </template>
                <template v-slot:body>
                    <span>
                        Applications
                    </span>
                </template>
              </hb-tooltip>
            </template>
            <app-launcher :launcher-drawer.sync="launcherDrawer" 
            @drawerOpened="handleDrawerChange"
            @closeAppLauncher="showLauncher = !showLauncher"
            ></app-launcher>
          </v-menu>
        </v-list-item>

        <!--
        <v-list-item>
          <a @click="globalDownload = true" class="align-self-center">
            <span class="nav-icon" style="margin-left:7%">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px" viewBox="0 0 24 24">
                <ellipse cx="10.0313" cy="2.63981" rx="10.0313" ry="2.63981" fill="#788F9B"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.81711V4.01245C0.735276 4.69142 4.2541 6.15382 10.1888 6.04936C15.755 5.95139 19.5023 4.69142 20.01 4.01245L20.0616 8.78096C20.0622 8.79299 20.0625 8.80504 20.0625 8.81711C20.0625 8.82426 20.0624 8.8314 20.0622 8.83854L20.0625 8.8697H20.0605C19.9519 10.2742 15.5033 11.4041 10.0313 11.4041C4.55928 11.4041 0.110616 10.2742 0.00203107 8.8697H0V8.81711Z" fill="#788F9B"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 14.9415V10.137C0.735276 10.8159 4.2541 12.2783 10.1888 12.1739C15.755 12.0759 19.5023 10.8159 20.01 10.137L20.0616 14.9053C20.0622 14.9174 20.0625 14.9294 20.0625 14.9415C20.0625 14.9487 20.0624 14.9558 20.0622 14.963L20.0625 14.9942H20.0605C19.9517 16.3987 15.5031 17.5285 10.0313 17.5285C4.55942 17.5285 0.110842 16.3987 0.00203937 14.9942H0V14.9415Z" fill="#788F9B"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 21.0657V16.2612C0.735276 16.9402 4.2541 18.4026 10.1888 18.2981C15.755 18.2002 19.5023 16.9402 20.01 16.2612L20.0616 21.0295C20.0622 21.0415 20.0625 21.0536 20.0625 21.0657C20.0625 21.0728 20.0624 21.08 20.0622 21.0871L20.0625 21.1185H20.0605C19.9514 22.5229 15.503 23.6527 10.0313 23.6527C4.55958 23.6527 0.111097 22.5229 0.00204873 21.1185H0V21.0657Z" fill="#788F9B"/>
                <path d="M17 14H13V8H7V14H3L10 21L17 14Z" fill="white"/>
              </svg>
            </span>
          </a>
        </v-list-item>
        -->

        <!--
        <v-list-item v-for="(item, index) in footerNavLinks" :key="index">
          <nav-link :item="item" />
        </v-list-item>
        -->
        <!-- <v-list-item class="pl-0 pr-1 my-sm-1" :class="{ 'pl-3' : !$vuetify.breakpoint.xs }">
          <v-btn
            color="#677380"
            medium
            icon
            to="/market"
            disabled
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 4H4V0H0V4ZM6 16H10V12H6V16ZM4 16H0V12H4V16ZM0 10H4V6H0V10ZM10 10H6V6H10V10ZM12 0V4H16V0H12ZM10 4H6V0H10V4ZM12 10H16V6H12V10ZM16 16H12V12H16V16Z" fill="#637381"/>
</svg>

          </v-btn>
        </v-list-item> -->
        <v-list-item v-if="!isSettings" class="my-sm-1 pr-1" :style="$vuetify.breakpoint.xs ? 'padding-left:9px;' : 'padding-left:9px;'">
          <hb-btn
            icon
            tooltip="Settings"
            @click="showSettingsModal"
            active-state-off
          >
            mdi-settings
          </hb-btn>
        </v-list-item>
        <v-list-item class="my-sm-1 pr-1" :style="$vuetify.breakpoint.xs ? 'padding-left:19px;' : 'padding-left:9px;'">
          <help-juice nav />
        </v-list-item>
        <v-list-item v-if="hasPermission('show_liveswitch')" class="my-sm-1 pr-1"
          :style="$vuetify.breakpoint.xs ? 'padding-left:9px;' : 'padding-left:9px;'">
          <v-tooltip right v-model="showTooltip" content-class="custom-tooltip-popup">
            <template v-slot:activator="{ on, attrs }">
              <span class="mdi--bell-outline" ref="triggerElement" @click="startConversation"></span>
            </template>
            <div class="tooltip" ref="tooltipElement">
              <div class="tooltip-text">
                <p>Need to talk to <br/>a live agent?</p>
                <hb-checkbox v-model="showAgain" label=" Don’t show again" @change="setCookiePreference" />
              </div>
              <hb-icon style="margin-bottom: 50px; cursor: pointer;" @click="hideTooltip">mdi-close</hb-icon>
            </div>
          </v-tooltip>
        </v-list-item>

        <hb-modal
          v-model="showNoPermissionDialog"
          size="medium"
          title="Unable to Access Settings"
          :footerOff="true"
          confirmation
          @close="showNoPermissionDialog = false"
        >
          <template v-slot:content>
            <div class="px-6 py-4">
              <div class="pb-2">
                The signed in account does not have permission to access settings. To access settings, please contact your administrator.
              </div>
            </div>
          </template>
        </hb-modal>
        <v-list-item class="pr-3 mb-sm-4 mt-sm-2" :style="$vuetify.breakpoint.xs ? 'padding-left:14px;' : 'padding-left:14px;'">
          <v-avatar color="#101318" size="32">
            <span class="white--text" style="font-size:18px;">{{contact.first.substring(0, 1).toUpperCase()}}</span>
          </v-avatar>
          <!-- delete the above v-avatar element and replace with v-btn element below when user page is ready -->
          <!--
          <v-btn icon to="/user">
            <v-avatar color="#101318" size="32">
              <span class="white--text" style="font-size:18px;">{{contact.first.substring(0, 1).toUpperCase()}}</span>
            </v-avatar>
          </v-btn>
          -->
        </v-list-item>
      </v-list>
    </v-layout>

    <!--
    <v-dialog v-model="globalDownload" max-width="600px" style="z-index:99999;">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title><span class="subtitle-1 pl-4">Download Application Data</span></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeExport">
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="height: 250px">
          <div v-show="!downloadStatus" class="text-center subtitle-1 hb-text-night" style="padding:7% 30% 3% 30%">
            You will be downloading all of your application data
            <div class="mt-3">
              <svg width="68" height="81" viewBox="0 0 68 81" fill="none" xmlns="http://www.w3.org/2000/svg">
              <ellipse cx="34" cy="8.94737" rx="34" ry="8.94737" fill="#677380"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 29.8843V13.5999C2.49215 15.9012 14.4188 20.8578 34.534 20.5038C53.4002 20.1717 66.1012 15.9012 67.822 13.5999L67.9967 29.7617C67.9989 29.8025 68 29.8434 68 29.8843C68 29.9086 67.9996 29.9328 67.9989 29.9571L68 30.063H67.9931C67.6241 34.8232 52.5462 38.6528 34 38.6528C15.4538 38.6528 0.375872 34.8232 0.00691896 30.063H0V29.8843Z" fill="#677380"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 50.6428V34.3582C2.49215 36.6595 14.4188 41.6161 34.534 41.2621C53.4002 40.93 66.1012 36.6595 67.822 34.3582L67.9967 50.5201C67.9989 50.561 68 50.6018 68 50.6428C68 50.667 67.9996 50.6913 67.9989 50.7155L68 50.8213H67.9931C67.6244 55.5816 52.5464 59.4112 34 59.4112C15.4536 59.4112 0.375594 55.5816 0.00690877 50.8213H0V50.6428Z" fill="#677380"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 71.4004V55.116C2.49215 57.4173 14.4188 62.3739 34.534 62.0199C53.4002 61.6878 66.1012 57.4173 67.822 55.116L67.9967 71.2777C67.9989 71.3185 68 71.3594 68 71.4004C68 71.4246 67.9996 71.4489 67.9989 71.4731L68 71.5791H67.9931C67.624 76.3393 52.5461 80.1688 34 80.1688C15.4539 80.1688 0.376033 76.3393 0.00692488 71.5791H0V71.4004Z" fill="#677380"/>
              <path d="M57.6198 47.4516H44.0622V27.1152H23.7258V47.4516H10.1682L33.894 71.1775L57.6198 47.4516Z" fill="white"/>
              </svg>
            </div>

          </div>
          <div v-show="downloadStatus" class="text-center subtitle-1 hb-text-night" style="padding:7% 25% 3% 25%">
            Thank you! Once the download is complete your data will be sent to the following email address
            <br />
            <br />
            administrator@storagecompany.com

          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="py-4 mr-4">
          <div class="text-right">
              <v-progress-circular
                color="primary"
                indeterminate
                v-show="exportLoading"
                class="my-0"
              ></v-progress-circular>
              <v-icon v-show="downloadStatus && !exportLoading" color="success">mdi-check</v-icon> <span class="mr-4 green--text" v-show="downloadStatus && !exportLoading">Download Complete</span>&nbsp;&nbsp;
              <v-btn v-show="!downloadStatus" class="text-capitalize" color="primary" @click="exportLoad" type="submit" value="Download">Download</v-btn>
              <v-btn v-show="downloadStatus" class="text-capitalize" color="primary" @click="closeExport" type="submit" value="Done">Done</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    -->
    <app-settings v-if="showSettings" @hideSettings="hideSettingsModal" />
    <app-dialog v-if="showAppDialog" :gdsUrl="getGdsDomain" :apiKey="getGdsApiKey" :app-data="appDialogData"/>
    <app-subscription-window 
      v-if="showSubscriptionDialog"
      :screenLoader="subscriptionScreenLoader"
      :totalApplications="getTotalApplications()" 
      :hasErrors="hasErrors"
    />

  </v-navigation-drawer>
</template>
<script>
import NavLink from './NavLink.vue';
import NotificationsList from '../Notifications/List.vue';
// import TodoCreate from '../Todo/Create.vue';
import Todo from '../Todo.vue';
import { mapGetters, mapActions } from 'vuex';
import { EventBus } from '../../../EventBus.js';
import api from '../../../assets/api.js'
import AppLauncher from "../../applications/AppLauncher";
import AppSubscriptionWindow from "../../applications/AppSubscriptionWindow";
export default {

    props: ['isSettings', 'dashboardLoader', 'drawer'],
    data() {
        return {
            showTooltip: true, // Initially show the tooltip
            showAgain: false, // Get the cookie value
            appDialogData: null,
            showLauncher: false,
            showAppLauncher: false,
            showAppDialog: false,
            showSettings: false,
            launcherDrawer: false,
            globalDownload: false,
            downloadStatus: false,
            exportLoading: false,
            todo: false,
            showNoPermissionDialog: false,
            headerNavLinks: [
              {
                title: 'Dashboard',
                to:'/dashboard',
                class: 'align-self-center',
                activeClass:'active',
                //action: () => { this.$store.commit('setNavState', { open: !this.$store.getters.isNavOpen}) },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 24 24;',
                    viewBox: '0 0 24 24',
                    path: {
                        fill: '#788f9b',
                        class: 'icon-path',
                        d: 'M10 20V14H14V20H19V12H22L12 3L2 12H5V20H10Z'
                    }
                } //M2.02002 12C2.02002 6.49002 6.49002 2.02002 12 2.02002C17.51 2.02002 21.98 6.49002 21.98 12C21.98 17.51 17.51 21.98 12 21.98C6.49002 21.98 2.02002 17.51 2.02002 12ZM11.48 13.74V20L16.35 10.26H13V4.00002L8.00002 13.74H11.48Z
            },
            {
                title: 'Search',
                class: 'align-self-center',
                activeClass:'active',
                action: {
                  command: 'HB-Navigation:Search',
                  payload: {
                    foo: 'bar'
                  }
                },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 24 24;',
                    viewBox: '0 0 24 24',
                    path: {
                        class: 'icon-path',
                        d: 'M15.502 14H14.708L14.432 13.726C15.407 12.589 16 11.115 16 9.50003C16 5.91003 13.09 3.00003 9.5 3.00003C5.91 3.00003 3 5.91003 3 9.50003C3 13.09 5.91 16 9.5 16C11.115 16 12.588 15.408 13.725 14.434L14.001 14.708V15.5L18.999 20.491L20.49 19L15.502 14ZM9.5 14C7.014 14 5 11.986 5 9.50003C5 7.01503 7.014 5.00003 9.5 5.00003C11.985 5.00003 14 7.01503 14 9.50003C14 11.986 11.985 14 9.5 14Z'
                    }
                }
             },
                {
                title: 'Offline',
                class: 'align-self-center',
                activeClass:'active',
                action: {
                  command: 'HB-Navigation:QuickLinks',
                  payload: {
                    foo: 'bar'
                  }
                },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 24 24;',
                    viewBox: '0 0 24 24',
                    path: {
                        class: 'icon-path',
                        d: 'M2.02002 12C2.02002 6.49002 6.49002 2.02002 12 2.02002C17.51 2.02002 21.98 6.49002 21.98 12C21.98 17.51 17.51 21.98 12 21.98C6.49002 21.98 2.02002 17.51 2.02002 12ZM11.48 13.74V20L16.35 10.26H13V4.00002L8.00002 13.74H11.48Z'
                    }
                }
              }
            ],
            footerNavLinks: [
              {
                title: 'Supervised User',
                to:'/user',
                class: 'align-self-center',
                activeClass:'active',
                action: () => { this.$store.commit('setNavState', { open: !this.$store.getters.isNavOpen}) },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 24 24;',
                    viewBox: '0 0 24 24',
                    path: {
                        class: 'icon-path',
                        d: 'M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM15.61 8.34C16.68 8.34 17.54 9.2 17.54 10.27C17.54 11.34 16.68 12.2 15.61 12.2C14.54 12.2 13.68 11.34 13.68 10.27C13.67 9.2 14.54 8.34 15.61 8.34ZM11.97 9.12C11.97 7.82 10.91 6.76 9.61 6.76C8.3 6.76 7.25 7.81 7.25 9.12C7.25 10.42 8.31 11.48 9.61 11.48C10.91 11.48 11.97 10.42 11.97 9.12ZM9.61 15.89V19.64C7.21 18.89 5.31 17.04 4.47 14.68C5.52 13.56 8.14 12.99 9.61 12.99C10.14 12.99 10.81 13.07 11.51 13.21C9.87 14.08 9.61 15.23 9.61 15.89ZM11.21 19.96C11.47 19.99 11.73 20 12 20C15.39 20 18.28 17.88 19.45 14.91C18.53 14.15 16.68 13.76 15.61 13.76C14.15 13.76 11.21 14.47 11.21 15.89V19.96Z'
                    }
                }
            },
              {
                title: 'Apps',
                to:'/market',
                class: 'align-self-center',
                activeClass:'active',
                action: () => { this.$store.commit('setNavState', { open: !this.$store.getters.isNavOpen}) },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 24 24;',
                    viewBox: '0 0 24 24',
                    path: {
                        class: 'icon-path',
                        d: 'M4 8H8V4H4V8ZM10 20H14V16H10V20ZM8 20H4V16H8V20ZM4 14H8V10H4V14ZM14 14H10V10H14V14ZM16 4V8H20V4H16ZM14 8H10V4H14V8ZM16 14H20V10H16V14ZM20 20H16V16H20V20Z'
                    }
                }
            },
                {
                title: 'Settings',
                to:'/settings',
                class: 'align-self-center',
                activeClass:'active',
                //action: () => { this.$store.commit('setNavState', { open: !this.$store.getters.isNavOpen}) },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 24 24;',
                    viewBox: '0 0 24 24',
                    path: {
                        class: 'icon-path',
                        d: 'M19.5001 12C19.5001 12.34 19.4701 12.66 19.4301 12.98L21.5401 14.63C21.7301 14.78 21.7801 15.05 21.6601 15.27L19.6601 18.73C19.5401 18.95 19.2801 19.04 19.0501 18.95L16.5601 17.95C16.0401 18.34 15.4801 18.68 14.8701 18.93L14.4901 21.58C14.4601 21.82 14.2501 22 14.0001 22H10.0001C9.75008 22 9.54008 21.82 9.51008 21.58L9.13008 18.93C8.52008 18.68 7.96008 18.35 7.44008 17.95L4.95008 18.95C4.73008 19.03 4.46008 18.95 4.34008 18.73L2.34008 15.27C2.22008 15.05 2.27008 14.78 2.46008 14.63L4.57008 12.98C4.53008 12.66 4.50008 12.33 4.50008 12C4.50008 11.67 4.53008 11.34 4.57008 11.02L2.46008 9.37C2.27008 9.22 2.21008 8.95 2.34008 8.73L4.34008 5.27C4.46008 5.05 4.72008 4.96 4.95008 5.05L7.44008 6.05C7.96008 5.66 8.52008 5.32 9.13008 5.07L9.51008 2.42C9.54008 2.18 9.75008 2 10.0001 2H14.0001C14.2501 2 14.4601 2.18 14.4901 2.42L14.8701 5.07C15.4801 5.32 16.0401 5.65 16.5601 6.05L19.0501 5.05C19.2701 4.97 19.5401 5.05 19.6601 5.27L21.6601 8.73C21.7801 8.95 21.7301 9.22 21.5401 9.37L19.4301 11.02C19.4701 11.34 19.5001 11.66 19.5001 12ZM8.50008 12C8.50008 13.93 10.0701 15.5 12.0001 15.5C13.9301 15.5 15.5001 13.93 15.5001 12C15.5001 10.07 13.9301 8.5 12.0001 8.5C10.0701 8.5 8.50008 10.07 8.50008 12Z'
                    }
                }
            },{
                title: 'properties',
                to:'/properties',
                class: 'align-self-center',
                activeClass:'active',
                //action: () => { this.$store.commit('setNavState', { open: !this.$store.getters.isNavOpen}) },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 64 64;',
                    viewBox: '0 0 64 64',
                    path: {
                        class: 'icon-path',
                        d: 'M0.6,9.6v49c0,0.6,0.4,1,1,1h61.1c0.6,0,1-0.4,1-1v-49c0-0.6-0.4-1-1-1H1.6C1.1,8.6,0.6,9.1,0.6,9.6z M57.7,54.7h-5.8c-0.6,0-1-0.4-1-1V33.2h2.3c0.4,0,0.7-0.3,0.7-0.7v-3.5c0-0.4-0.3-0.7-0.7-0.7H11.1c-0.4,0-0.7,0.3-0.7,0.7v3.5c0,0.4,0.3,0.7,0.7,0.7h2.3v20.5c0,0.6-0.4,1-1,1H6.6c-0.6,0-1-0.4-1-1v-39c0-0.6,0.4-1,1-1h51.1c0.6,0,1,0.4,1,1v39C58.7,54.2,58.3,54.7,57.7,54.7z'
                    }
                }
            }
          ],
          tasksLength: 0,
          pendingMoveIns: [],
          showTasksIndicator: false,
          showSubscriptionDialog: false,
          subscriptionScreenLoader: false,
          hasErrors: false
        }
    },
    computed: {
        ...mapGetters({
          contact: 'authenticationStore/getUserData',
          isAdmin: 'authenticationStore/isAdmin',
          tasksCount: 'taskCenterStore/tasksCount',
          hasPlatformApps: 'authenticationStore/hasPlatformApps',
          isHBUser: 'authenticationStore/isHBUser',
          messageCount: 'notificationsStore/messageCount',
          hasPermission: 'authenticationStore/rolePermission',
          applicationResponse: 'authenticationStore/getApplicationEndpointResponse',
          hbSubscribedApplications: 'authenticationStore/getHbSubscribedApplications'
        }),

        spaceAccessCodesModeTooltip(){
          if(this.spaceAccessCodesMode){
            return 'Space Gate Access Mode Enabled. current state: "spaceAccessCodesMode = true" (note: this gate icon only shows in uat / is for testing only)'
          } else {
            return 'Enable Space Gate Access Mode. current state: "spaceAccessCodesMode = false" (note: this gate icon only shows in uat / is for testing only)';
          }
        },
        getGdsDomain() {
          return process.env.VUE_APP_GDS_BASE_URL +"/v3";
        },
        getGdsApiKey() {
          return process.env.VUE_APP_GDS_API_KEY;
        },

        initial(){
            return this.contact.first ? this.contact.first.substring(0,1): 'H';
        },
        tasksCounter(){
          let count = 0;
          if (this.tasksCount && this.tasksCount.length) {
            count = count + this.tasksCount.find( arrEl => arrEl.event_type_name == 'all').count;
          }
          if (this.pendingMoveIns.length && this.pendingMoveIns.length > 0) {
            count = count + this.pendingMoveIns.length;
          }
          return count;
        }
    },
    watch: {
      showAppDialog(isAppOpen) {
        if (!isAppOpen) {
          this.$store.dispatch('navigationStore/setAppLauncher', null);
          this.appDialogData = null
        }
      },
      showSubscriptionDialog(isAppOpen) {       
        if (!isAppOpen) {
          this.$store.dispatch('navigationStore/setAppLauncher', null);
        }
      },
      showSettings: {
        immediate: true,
        handler(isSettingsOpen) {
          this.$emit("settingsStatus", isSettingsOpen)
        }
      },
      showTooltip(val) {
      if (val) {
        this.positionTooltip();
        window.addEventListener('resize', this.positionTooltip);
      } else {
        window.removeEventListener('resize', this.positionTooltip);
      }
    }
    },
    methods: {
      positionTooltip() {
      this.$nextTick(() => {
        const triggerElement = this.$refs.triggerElement;
        const tooltipElement = this.$refs.tooltipElement;

        if (triggerElement && tooltipElement) {
          const triggerRect = triggerElement.getBoundingClientRect();
          const tooltipRect = tooltipElement.getBoundingClientRect();

          // Calculate the position
          const top = triggerRect.top + window.scrollY - (tooltipRect.height / 2) + (triggerRect.height / 2);
          const left = triggerRect.right + 10; // Adjust for your design

          // Apply the position
          tooltipElement.style.top = `${top}px`;
          tooltipElement.style.left = `${left}px`;
        }
      });
    },
      hideTooltip() {
        this.showTooltip = false;
      },
      setCookiePreference() {
        document.cookie = `showTooltip=${!this.showAgain}; path=/; max-age=31536000`; // 1 year
        this.showTooltip = false
      },
      getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
      },
      startConversation() {
        console.log(this.contact,"======");
        // Check if liveSwitchConcierge is defined
        if (typeof liveSwitchConcierge !== 'undefined') {
          liveSwitchConcierge.startConversation("tenantinc-com", {
            position: "right",
          },
            {
              firstName: this.contact.first,
              lastName: this.contact.last,
              phoneNumber: "",
              emailAddress: this.contact.email
            })
        } else {
          console.error("liveSwitchConcierge is not defined. Make sure the script is loaded properly.");
        }
      },
      createTask(){
        this.todo = true;
      },
      closeAppLauncher(val) {
        this.showLauncher = val
      },
      handleDrawerChange(val) {
        this.launcherDrawer = val
      },
      ...mapActions({
          setTaskCounts: 'taskCenterStore/setTaskCounts',
          getMessageCount: 'notificationsStore/getMessageCount',
          setNectarApplications: "authenticationStore/setNectarApplications",
      }),
      showSettingsModal() {
        if (this.hasPermission("access_settings")) {
          this.closeAppDialog()
          this.$nextTick(() => {
            this.showSettings = true;
            let element = document.querySelector('body')
            element.classList.add('hide-scroll')
          })
        } else {
          this.showNoPermissionDialog = true;
        }
      },
      hideSettingsModal() {
        this.showSettings = false;
        let element = document.querySelector('body')
        element.classList.remove('hide-scroll')  
      },
      closeExport(){
        this.globalDownload = false;
        this.exportLoading = false;
        this.downloadStatus = false;
      },
      exportLoad() {
        this.exportLoading = true;
        this.downloadStatus = true;
        setTimeout(() => {
          this.exportLoading = false;
        }, 2000);
      },
      goSearch(){
        var payload = { foo: 'bar' };
        EventBus.$emit('HB-Navigation:Search', payload);
      },
      goQuickLinks(){
        var payload = { foo: 'bar' };
        EventBus.$emit('HB-Navigation:QuickLinks', payload);
      },
      goDelinquencyCenter() {
        EventBus.$emit('HB-Navigation:DelinquencyCenter');
      },
      goLeadFollowUp() {
        EventBus.$emit('HB-Navigation:LeadFollowUp');
      },
      goTaskCenter() {
        EventBus.$emit('HB-Navigation:TaskCenter');
      },
      goCommunicationCenter() {
        EventBus.$emit('HB-Navigation:CommunicationCenter');
      },
      async findPending(){
          let r = await api.get(this, api.LEASES +  'pending', { property_id: '' });
          this.pendingMoveIns = r.leases;
      },
      eventShowSettings(){
        this.showSettings = false;
        this.showAppDialog = false
      },
      eventShowTasksIndicator(value){
        this.showTasksIndicator = value;
      },
      openAppDialog(value) {
        this.hideSettingsModal()
        this.$nextTick(() => {
          this.showAppDialog = true
          this.appDialogData = value
        })
      },

      async openSubscriptionDialog() {
        this.hasErrors = false;
        this.hideSettingsModal()
        this.$nextTick(() => {
          this.showSubscriptionDialog = true
          let element = document.querySelector("body");
          element.classList.add('hide-scroll')
        })
        this.subscriptionScreenLoader = true;
        try {
          await this.setNectarApplications();
        } catch(e) {
          this.hasErrors = true;
        }
        this.subscriptionScreenLoader = false;
      },
      closeAppDialog() {
        this.showAppDialog = false
        this.showSubscriptionDialog = false 
        let element = document.querySelector("body");
        element.classList.remove('hide-scroll')
        this.$store.dispatch('navigationStore/setAppLauncher', null);
      },
      closeCreateTask() {
        this.todo = false;
      },
      toggleGateAccessView(){
        if(this.spaceAccessCodesMode){
            this.$store.dispatch('authenticationStore/disableAllowSpaceAccessCodes');
        } else {
            this.$store.dispatch('authenticationStore/enableAllowSpaceAccessCodes');
        }
      },
      getTotalApplications() {
        let installedApplications = []

        this.applicationResponse?.installed_apps?.forEach((application) => {
          if (application.app_id) {
            let appDetails = this.hbSubscribedApplications.find((subscribedApp) => subscribedApp.app_urn === application.app_id);
            if (appDetails) {
              installedApplications.push(application);
            }
          }
        });
        return { available_apps: this.applicationResponse.available_apps ?? [], installed_apps: installedApplications};
      }, 

    },
    components: {
      AppLauncher,
      // TodoCreate,
      Todo,
      NavLink,
      NotificationsList,
      AppSubscriptionWindow,
      'app-settings': () => import('../../settings/Index.vue'),
      'app-dialog': () => import('../../applications/AppDialog')
    },
    mounted() {
      window.addEventListener('resize', this.positionTooltip);
      if (this.getCookie('showTooltip') === 'false') {
        this.showTooltip = false;
      }
      this.showAgain = this.getCookie('showTooltip')
    },
    created() {
      if(!this.isHBUser) {
        this.showSettings = true;
      }
      this.setTaskCounts({ role_ids: this.rolesIDs });
      this.findPending();
      this.getMessageCount();
      EventBus.$on('HB-Navigation:showSettingsModal', this.showSettingsModal)
      EventBus.$on('HB-Navigation:Hide-Settings-Modal', this.eventShowSettings);
      EventBus.$on('HB:Navigation-tasks-indicator', this.eventShowTasksIndicator);
      EventBus.$on('openAppDialog', this.openAppDialog);
      EventBus.$on('closeAppDialog', this.closeAppDialog);
      EventBus.$on('openSubscriptionDialog', this.openSubscriptionDialog);
      
      EventBus.$on('createTask', this.createTask);
      EventBus.$on('closeCreateTask', this.closeCreateTask);
    },
    destroyed() {
      EventBus.$off('HB-Navigation:showSettingsModal', this.showSettingsModal)
      EventBus.$off('HB-Navigation:Hide-Settings-Modal', this.eventShowSettings);
      EventBus.$off('HB:Navigation-tasks-indicator', this.eventShowTasksIndicator);
      EventBus.$off('openAppDialog', this.openAppDialog);
      EventBus.$off('closeAppDialog', this.closeAppDialog);
      EventBus.$off('createTask', this.createTask);
      EventBus.$off('closeCreateTask', this.closeCreateTask);
      EventBus.$off('openSubscriptionDialog', this.openSubscriptionDialog);
    },
    beforeDestroy() {
    window.removeEventListener('resize', this.positionTooltip);
    },
}
</script>
<style lang="scss">
.custom-tooltip-popup {
    background: transparent !important;
    opacity: 1 !important;
    top: 768px !important;
    left: 43px !important;
    pointer-events: all !important;
    z-index: 6 !important;

  .tooltip {
    position: fixed;
    background-color: #e6f7f7;
    color: #000;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    /* Adjust the width as needed */
    white-space: normal;
    /* or 'normal', 'pre', 'pre-wrap' as per your design */
  }

  .tooltip::before,
  .tooltip::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -25px;
    /* Adjust as necessary to position the arrow correctly */
    transform: translateY(-50%);
    border-width: 15px;
    border-style: solid;
    pointer-events: none;
  }

  .tooltip::before {
    border-color: transparent #e6f7f7 transparent transparent;
  }

  .tooltip::after {
    border-color: transparent rgba(80, 79, 79, 0.1) transparent transparent;
    left: -26px;
    /* Slightly offset to show the shadow */
    z-index: -1;
    /* Ensure the shadow is behind the tooltip */
  }

  .tooltip-text {
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 8px !important;
      font-size: 16px;
      font-weight: 500;
    }

    div {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .tooltip .tooltip-text-heading {
    font-size: 16px;
    font-weight: 500;
  }
}

.hide-scroll {
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;  
}

#hb-navigation {
  display: flex;
  flex-direction: column;
  width: 60px;
  transform: translateX(0%) !important;
  visibility: unset !important;
  .v-list.isMobile {
    display: flex !important;
    flex-direction: row;
    padding: 8px 15px;
    .v-list-item {
      padding: 0 10px;
    }
  }
  &.isMobile {
    height: 60px !important;
    bottom: 0;
    position: fixed;
    top: initial !important;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
      .v-navigation-drawer__content {

      }
  }
}

.custom-navigation-drawer {
  z-index: 5!important;
}

.platformApp.menuable__content__active.theme--light.v-menu__content {
    left: 0px !important;
    top: 0px !important;
    overflow:  hidden;
    position: fixed;
    z-index: 255 !important;
}
.app-launcher-icon-btn.v-btn.v-btn--depressed.theme--light.v-size--default {
  height: 40px;
  min-width: 40px !important;
  background-color: transparent !important;
  padding: 0px !important;
  border-radius: 50% !important;
}
.app-launcher-icon-btn.v-btn:before {
  background: currentColor;
}


    /*#hb-nav {*/
    /*    min-width: 56px;*/
    /*    display: flex;*/
    /*    align-items: stretch;*/
    /*    .hb-nav--inner {*/
    /*        position: fixed;*/
    /*        top: 0;*/
    /*        left: 0;*/
    /*        bottom: 0;*/
    /*        background: rgba(255,255,255,1);*/
    /*        border-right: 1px solid rgba(0,0,0,0.25);*/
    /*        width: 56px;*/
    /*        padding-top:70px;*/
    /*        &>.row {*/
    /*            padding: 5px;*/
    /*        }*/
    /*        .row {*/
    /*            span.nav-icon{*/
    /*                display: inline-block;*/
    /*                height: 24px;*/
    /*                width: 24px;*/
    /*                vertical-align: top;*/
    /*                margin-right: 0px;*/
    /*            }*/
    /*            .col {*/
    /*                padding: 0;*/
    /*            }*/
    /*        }*/
    /*        v-col {*/
    /*            text-align: center;*/
    /*        }*/
    /*    }*/
    /*    .hb-nav--footer {*/

    /*    }*/
    /*}*/






    /********************
    Navigation Styles
   *********************/

    .nav{
      transition: all .3s;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }

    span.nav-header {
      display: block;
      margin: 35px 0 10px 45px;
    }

    span.nav-header h3 {
      font-size: 12px;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      color: #788f9b;
      letter-spacing: 1px;
      text-transform: uppercase;

    }

    .nav-wrapper{
      overflow-x: hidden;
    }
    .navigation {
      width: 100%;
      position: relative;
    }
    .navigation ul {
      margin: 0 0 0 0px;
      padding: 0;
      list-style: none;

    }

    .navigation ul li{
      padding: 0px 0;
      margin: 0;
    }



    /*.v-navigation-drawer__content a{*/
    /*    color: #788f9b;*/
    /*    font-family: "Roboto", sans-serif;*/
    /*    font-weight: 500;*/
    /*    font-size: 14px;*/
    /*    cursor: pointer;*/
    /*    line-height: 20px;*/
    /*    display: block;*/

    /*    padding: 7px 5px 7px 45px;*/
    /*    position: relative;*/
    /*    fill: currentColor;*/
    /*    -webkit-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);*/
    /*    -moz-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);*/
    /*    -o-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);*/
    /*    border-top: 1px solid #FFFFFF;*/
    /*    border-bottom: 1px solid #FFFFFF;*/

    /*}*/

    .icon-path-dark{
      -webkit-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
      -moz-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
      -o-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
      fill: #788f9b;
    }


    .icon-path{
      -webkit-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
      -moz-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
      -o-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
      fill: #788f9b;
    }

    .v-navigation-drawer__content a.v-list-item--active .icon-path{
      fill: #00b2ce;
    }

    .v-navigation-drawer__content a.v-list-item--active .icon-path-dark{
      fill: #00a1c8;
      fill: #263238;
    }

    /* this style shouldn't be necessary, commenting it out
    .v-navigation-drawer__content a.v-list-item--active{
      color: #00a1c8;
      border-top: 1px solid #e4eff4;
      border-bottom: 1px solid #e4eff4;
    }
    */

    .navigation ul li a,
    .navigation ul li a:hover,
    .navigation ul li a:visited,
    .navigation ul li a:active{
      text-decoration: none;
    }
    span.nav-text {
      line-height: 20px;
    }
    span.nav-icon{
      display: inline-block;
      height: 20px;
      width: 20px;
      vertical-align: top;
    }

    .company-switcher{
      margin: 0 10px 10px;
      display: none;
      padding: 15px 30px 0;
    }

    .close-link-row {
      display: none;
    }

    .v-progress-circular {
      margin: 1rem;
    }

    .v-dialog{
      box-shadow: 0 !important;
    }

    .notifications-custom-padding{
      padding-left:11px;
    }
    .task-indicator{
      position: absolute;
      padding: 4.5px;
      background-color: red;
      border-radius: 50%;
      right: 25%;
      top: 25%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    @media (max-width: 991px){

      .nav {
        margin-left: -225px;
        width: 200px;
        position: fixed;
        top: 0px;
        bottom: 0;
        overflow: auto;
        z-index: 7000;


      }

      .nav-open .nav{
        margin-left: 0px;
        width: 100%;
      }

      .company-switcher{
        display:block;
      }

      .close-link-row {
        display: block;
        height: 30px;
      }


    }



    @media (max-width: 893px) {

      .navbar-toggle {
        display: block;
      }

    }
    @media (max-width: 768px) {
      .sm-remove{
        display:none;
      }
      /*.nav-wrapper{*/
      /*transform:translateX(-225px);*/
      /*}*/
      /*.nav-open .nav-wrapper{*/
      /*transform:translateX(0px);*/
      /*width: 100%;*/
      /*}*/

      /*.nav-open .content-container{*/
      /*transform:translateX(225px);*/
      /*}*/

      .nav-open .navigation .hide-small{
        opacity: 1;
      }
    }

    @media (min-width: 600px){



    }

        .mdi--bell-outline {
          cursor: pointer;
          margin-left: 8px;
          display: inline-block;
          width: 1.9em;
          height: 1.9em;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12.021 6.02128C11.6076 6.02128 11.1942 6.02932 10.7815 6.01968C10.1155 6.00441 9.59565 5.48046 9.60264 4.84402C9.60963 4.20355 10.0953 3.69889 10.7512 3.68684C11.5905 3.67157 12.4305 3.66835 13.269 3.68523C13.9249 3.69809 14.4021 4.21078 14.3974 4.85446C14.3927 5.50216 13.914 6.00039 13.2605 6.01968C12.8478 6.03173 12.4337 6.02209 12.021 6.02209V6.02128Z' fill='%23637381'/%3E%3Cpath d='M12.0122 20.3169C9.90316 20.3169 7.79486 20.3193 5.68578 20.3152C4.93665 20.3136 4.2458 20.1264 3.71115 19.5454C2.82602 18.5835 2.73432 17.1756 3.62023 16.1679C4.67554 14.9681 5.2234 13.5522 5.63605 12.035C5.86607 11.1912 6.1233 10.345 6.47144 9.54786C7.13976 8.01942 8.34272 7.14511 9.92181 6.79233C11.138 6.52071 12.3565 6.51348 13.5843 6.69349C15.8838 7.0318 17.3051 8.39872 17.982 10.6496C18.2952 11.6919 18.5881 12.7414 18.9285 13.7748C19.2059 14.6177 19.6885 15.3418 20.2659 16.0048C20.9832 16.8292 21.2187 17.7719 20.7765 18.8125C20.3397 19.8419 19.5075 20.304 18.4545 20.3144C16.3073 20.3361 14.1594 20.3209 12.0122 20.3209V20.3177V20.3169ZM5.2778 17.89H18.7311C18.6239 17.7068 18.5773 17.5846 18.4957 17.4954C17.3922 16.2884 16.7775 14.8203 16.333 13.2557C16.0967 12.4255 15.8558 11.5954 15.5737 10.7814C15.2411 9.82189 14.5526 9.21758 13.5812 9.09463C12.7031 8.98374 11.8032 8.94838 10.9204 9.00463C9.76017 9.07936 8.83541 9.61616 8.41499 10.82C8.21838 11.3825 8.03343 11.9514 7.88034 12.5284C7.39542 14.3558 6.78462 16.114 5.48529 17.526C5.41224 17.6055 5.37338 17.718 5.27702 17.8892L5.2778 17.89Z' fill='%23637381'/%3E%3C/svg%3E");
        }
</style>
