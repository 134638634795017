<template>
  <v-navigation-drawer
    :width="$vuetify.breakpoint.xs ? 322 : 200"
    flat
    :value="drawer"
    class="fill-height mt-60 main-app-drawer-wrapper"
    :class="{'ml-60': $vuetify.breakpoint.smAndUp, 'navigation-overlay': $vuetify.breakpoint.width < 1260 && $vuetify.breakpoint.width > 600 }"
    disable-resize-watcher
    clipped
    fixed
    elevation-0
    mobile-breakpoint="600"
    id="main-navigation-drawer"
    style="visibility: unset;"
    scrim-class="drawer-navigation-mobile"
    :style="drawer ? ($vuetify.breakpoint.width <= 600 ? 'transform: translateX(18.6%)' : 'transform: translateX(0%)') : 'transform: translateX(-130%)'"
  >

    <v-list
        dense
        class="nav-drawer-list mt-2"
        style="width: 100%;"
    >
        <template v-for="(item, a) in newLinks">
            <v-list-item 
                class="py-1 px-4"
                v-if="item.items.length === 0 && (!item.isSuperAdmin || (item.isSuperAdmin && isSuperAdmin))"
                :to="item.to"
                :key="a"
                @click="onSelectionItem"
                :disabled="isDisabled(item)"
            >
                <v-list-item-action class="ma-0 mr-2">
                    <hb-icon :color="isDisabled(item) ? '#00000061': ''">{{item.icon}}</hb-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        <span class="hb-default-font-size">{{item.title}}</span>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-group
                append-icon="mdi-menu-down"
                v-else-if="!item.isSuperAdmin || (item.isSuperAdmin && isSuperAdmin)"
                dense
                :key="a"
                :disabled="isDisabled(item)"
            >
                <template v-slot:activator>
                    <v-list-item  class="pr-0 py-1 px-0" :disabled="isDisabled(item)">
                        <v-list-item-action class="ma-0 mr-2">
                            <hb-icon  :color="isDisabled(item) ? '#00000061': ''">{{item.icon}}</hb-icon>
                        </v-list-item-action>
                        <v-list-item-content style="min-width: 77px;">
                            <v-list-item-title>
                                <span :style="isHBUser ? 'color:#637381;' : ''" style="padding-left:1px;font-size:14px;font-weight:normal;" v-html="item.title"></span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-list-item @click="onSelectionItem" class="py-1" :to="item.to" :key="b" v-for="(item, b) in checkItemsPermission(item.items)">
                    <v-list-item-action class="ma-0 mr-2"></v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <span class="hb-default-font-size" style="white-space: normal;">{{item.title}}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list-group>
            <v-divider v-if="a !== newLinks.length -1" :key="'divider' + a" style="margin:0 20px;"></v-divider>
        </template>
    </v-list>

  </v-navigation-drawer>
</template>
<script type="text/babel">
	import Icons from '../../../src/mixins/icons.json';
  import api from '../../assets/api.js';
  import { mapGetters, mapActions } from 'vuex';
  import { EventBus } from '@/EventBus.js';
  const permissionsMap = {
    'Rent Management': {
      permission: 'access_rent_management',
      enabledFlag: 'isRentManagementEnabled'
    },
    'Rate Management': {
      permission: 'access_rate_management',
      enabledFlag: 'isRateManagementEnabled'
    },
    'Promotion & Discount Management': {
      permission: 'access_promotion_management',
      enabledFlag: 'isPromotionManagementEnabled'
    },
    'Business Intelligence': {
      permission: 'access_business_intelligence',
      enabledFlag: 'isBusinessIntelligenceEnabled'
    }
  };

  export default {
        name: 'Navigation',
        data() {
            return {
                alertVisible: false,
                selectedCompany: '',
                notifications:[],
                loaded: false,
                containerHeight: '',
                links: [
                    {
                        header: "Navigation",
                        targets: [{
                            to:'/dashboard',
                            title: "Dashboard",
                            icon: 'mdi-view-dashboard'
                        },{
                            to:'/properties',
                            title: "Properties",
                            icon: 'mdi-floppy'
                        },{
                            to:'/spaces',
                            title: "Spaces",
                            icon: 'mdi-facility-custom-3'
                        },{
                            to:'/leads',
                            title: "Leads",
                            icon: 'recent_actors',
                            type: 'md'
                        },{
                            to:'/tenants',
                            title: "Tenants",
                            icon: 'person',
                            type: 'md'
                        },{
                            to:'/maintenance-requests',
                            title: "Maintenance",
                            icon: Icons.maintenance,
                            type: 'svg'
                        },{
                            to:'/reports',
                            title: "Reports",
                            icon: Icons.reports,
                            type: 'svg'
                        },{
                            to:'/billing',
                            title: "Billing",
                            icon: Icons.billing,
                            type: 'svg'
                        },{
                            to:'/applications',
                            title: "Applications",
                            icon: 'subtitles',
                            type: 'md'
                        },
                        {
                            to:'/chats',
                            title: "Chats",
                            icon: 'mdi-chat'
                        }]
                    },
                    {
                        header: "Admistration",
                        targets: [{
                            to:'/settings',
                            title: "Settings",
                            icon: 'mdi-settings'
                        },{
                            to:'/users',
                            title: "Administrators",
                            icon: 'mdi-account-group'
                        },{
                            to:'/logout',
                            title: "Log Out",
                            icon: 'mdi-lock'
                        }]
                    },
                    {
                        header: "Super Admin",
                        isSuperAdmin: true,
                        targets: [{
                            to:'/admin/accounts',
                            title: "List Accounts",
                            icon: Icons.accounts,

                        }]
                    }
                ],
                newLinks: [
                    {
                        to:'/dashboard',
                        title: "Dashboard",
                        icon: 'mdi-view-dashboard',
                        items: []
                    },
                    {

                        to:'/spaces',
                        title: "Spaces",
                        icon: 'mdi-facility-custom-3',
                        items: []
                    },
                    {
                        to:'/tenants',
                        title: "Tenants",
                        icon: 'mdi-account',
                        items: []
                    },
                    {
                        to:'/leads',
                        title: "Leads",
                        icon: 'recent_actors',
                        items: []
                    },
                    {
                        to:'/reports-library',
                        title: "Reports",
                        icon: 'mdi-file-chart',
                        items: []
                    },
                    {
                        to:'',
                        title: "Tools",
                        icon: 'mdi-tools',
                        items: [
                            {
                              to:'/accounting',
                             title: "Accounting Export"
                          },
                            {
                              title: "Download Center",
                              to:'/download-center'
                          },
                        
                          {
                              title: "Notice Manager",
                              to:'/notice-manager'
                          },

                          {
                            title: "Promotion & Discount Management",
                            to: "/promotion-management"
                          },
                          
                          {
                              title: "Rent Management",
                              to:'/rent-management'
                          },
                          {
                              title: "Rate Management",
                              to:'/rate-management'
                          },
                          {
                              title: "Direct Refunds",
                              to:'/direct-refunds'
                          },
                          {
                              title: "Business Intelligence",
                              to:'/business-intelligence'
                          },
                          {
                              title: "Advanced Reservation and Rental",
                              to:'/advanced-purchase-window'
                          }
                        ]
                    },
                    {
                        to:'/logout',
                        title: "Log Out",
                        icon: 'mdi-lock',
                        items: []
                    }
                ],
                isRentManagementEnabled: true,
                isRateManagementEnabled: true,
                isPromotionManagementEnabled: true,
            }
        },
        computed: {
            ...mapGetters({
                isSuperAdmin: 'authenticationStore/isSuperAdmin',
                getCompanyNames: 'authenticationStore/getCompanyNames',
                isHBUser: 'authenticationStore/isHBUser',
                hasPermission: 'authenticationStore/rolePermission',
                isBusinessIntelligenceEnabled: 'authenticationStore/isBusinessIntelligenceEnabled',
            }),
        },
        props: ['drawer'],
        created(){
            this.getSettings();
        },
        watch: {
            // Clean up the event listener when the drawer is closed
            drawer(newValue) {
                if (!newValue) {
                    const scrim = document.querySelector('.v-overlay__scrim');
                    if (scrim) {
                    scrim.removeEventListener('click', this.onScrimClick);
                    }
                } else {
                    // Wait for the next DOM update to ensure the scrim is rendered
                    this.$nextTick(() => {
                        // Add a click event listener to the scrim
                        const scrim = document.querySelector('.v-overlay__scrim');
                        if (scrim) {
                            scrim.addEventListener('click', this.onScrimClick);
                        }
                    });
                }
            },
        },
        methods:{
            ...mapActions({
                setBusinessIntelligenceSettings: 'authenticationStore/setBusinessIntelligenceSettings'
            }),
            onScrimClick() {
                this.$emit('close', false)
            },
            setCompany(company){
                api.post(this, api.SWITCH_COMPANY, {company:company}).then(r => {
                    console.log(r);
                    window.location = r.r;
                });
            },
            onSelectionItem() {
                EventBus.$emit('turn-off-hamburger-drawer', false);
            },
            isDisabled(item) {
                return !this.isHBUser && item.to != '/logout'
            },
            checkItemsPermission(items) {
              return items.filter(item => {
                const config = permissionsMap[item.title];
                if (config) {
                  return this.hasPermission(config.permission) && this[config.enabledFlag];
                }
                return true;
              });
            },
            async getSettings(){
                let businessIntelligence = await api.get(this, api.SETTINGS + '?category=businessintelligence');
                let value = false;
                let businessIntelligenceLinkObject = {};
                if(businessIntelligence.settings?.length > 0) {
                    let settingObj = null;
                    settingObj = businessIntelligence.settings.find(x=>x.name === 'toggleBusinessIntelligence');
                    value = settingObj?.value;
                    value = value === '1';
                    businessIntelligenceLinkObject = businessIntelligence.settings.find(x=>x.name === 'businessIntelligenceLink');
                }
                this.setBusinessIntelligenceSettings({
                    toggleBusinesIntelligence: value,
                    businesIntelligenceLink: businessIntelligenceLinkObject?.value
                });
            }
        }
    }


</script>

<style>
    /********************
     Navigation Styles
    *********************/
    .mt-60 {
        margin-top: 55px;
    }
    .ml-60{
        margin-left: 60px;
    }
    .dl-60 {
        left: 60px;
    }

    .nav{
        transition: all .3s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }

    span.nav-header {
        display: block;
        margin: 35px 0 10px 45px;
    }

    span.nav-header h3 {
        font-size: 12px;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        color: #788f9b;
        letter-spacing: 1px;
        text-transform: uppercase;

    }

    .nav-wrapper{
        overflow-x: hidden;
    }
    .navigation {
        width: 100%;
        position: relative;
    }
    .navigation ul {
        margin: 0 0 0 0px;
        padding: 0;
        list-style: none;

    }

    .navigation ul li{
        padding: 0px 0;
        margin: 0;
    }




    /*.v-navigation-drawer__content a{*/
    /*    color: #788f9b;*/
    /*    font-family: "Roboto", sans-serif;*/
    /*    font-weight: 500;*/
    /*    font-size: 14px;*/
    /*    cursor: pointer;*/
    /*    line-height: 20px;*/
    /*    display: block;*/

    /*    padding: 7px 5px 7px 45px;*/
    /*    position: relative;*/
    /*    fill: currentColor;*/
    /*    -webkit-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);*/
    /*    -moz-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);*/
    /*    -o-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);*/
    /*    border-top: 1px solid #FFFFFF;*/
    /*    border-bottom: 1px solid #FFFFFF;*/

    /*}*/

    .icon-path-dark{
        -webkit-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
        -moz-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
        -o-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
        fill: #788f9b;
    }


    .icon-path{
        -webkit-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
        -moz-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
        -o-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
        fill: #788f9b;
    }

    .v-navigation-drawer__content a.v-list-item:not(.v-list-item--disabled) .v-list-item__title{
        color:#637381;
        font-weight:normal;
        padding-left:1px;
    }

    .v-navigation-drawer__content a.v-list-item--active .v-list-item__title .hb-default-font-size, .v-navigation-drawer__content a.v-list-item:hover .v-list-item__title .hb-default-font-size, .main-app-drawer-wrapper .v-list-item.v-list-item--link:hover .v-list-item__content * {
        color:#101318 !important;
        font-weight:500 !important;
    }

    .v-navigation-drawer__content a.v-list-item i {
        color:#637381;
    }

    .v-navigation-drawer__content a.v-list-item--active i, .v-navigation-drawer__content a.v-list-item:hover i, .main-app-drawer-wrapper .v-list-item.v-list-item--link:hover i {
        color:#101318 !important;
    }

    .v-navigation-drawer__content a.v-list-item--active svg path, .v-navigation-drawer__content a.v-list-item:hover svg path {
        fill:#101318 !important;
    }

    .v-navigation-drawer__content a.v-list-item--active .icon-path{
        fill: #00b2ce;
    }

    .v-navigation-drawer__content a.v-list-item--active .icon-path-dark{
        fill: #00a1c8;
        fill: #263238;
    }

    .main-app-drawer-wrapper .v-list-item.v-list-item--link:not(.v-list-item--active):hover::before {
        opacity: 0;
    }

    .main-app-drawer-wrapper .v-navigation-drawer__content a.v-list-item:not(.v-list-item--active) {
        background-color: #ffffff !important;
        border-left: 4px solid #ffffff;
    }

    .main-app-drawer-wrapper .v-navigation-drawer__content a.v-list-item:not(.v-list-item--active)::before, .main-app-drawer-wrapper .v-navigation-drawer__content a.v-list-item--link:not(.v-list-item--active)::before {
        background-color: #ffffff !important;
        border-left: 4px solid #ffffff;
    }

    .main-app-drawer-wrapper .v-navigation-drawer__content a.v-list-item--active{
        color: #47C0BF !important;
        border-left: 4px solid #00848E;
    }

    .v-navigation-drawer__content .v-list-item__icon.v-list-group__header__append-icon {
        justify-content: left !important;
        margin-left:0 !important;
    }

    .v-navigation-drawer__content .v-list-item__icon.v-list-group__header__append-icon i{
        color:#637381;
    }

    .nav-drawer-list{
        padding-bottom: 80px;
    }

    .navigation ul li a,
    .navigation ul li a:hover,
    .navigation ul li a:visited,
    .navigation ul li a:active{
        text-decoration: none;
    }
    span.nav-text {
        line-height: 20px;
    }
    span.nav-icon{
        display: inline-block;
        height: 20px;
        width: 20px;
        vertical-align: top;

    }

    .company-switcher{
        margin: 0 10px 10px;
        display: none;
        padding: 15px 30px 0;
    }

    .close-link-row {
        display: none;
    }

    #main-navigation-drawer .v-list-item__title {
        padding: 1px 0;
    }

    .navigation-overlay {
        z-index: 5!important;
    }

    .v-app-bar--fixed {
        z-index: 6!important;
    }

    @media (max-width: 991px){

        .nav {
            margin-left: -225px;
            width: 200px;
            position: fixed;
            top: 0px;
            bottom: 0;
            overflow: auto;
            z-index: 7000;


        }

        .nav-open .nav{
            margin-left: 0px;
            width: 100%;
        }

        .company-switcher{
            display:block;
        }

        .close-link-row {
            display: block;
            height: 30px;
        }


    }



    @media (max-width: 893px) {

        .navbar-toggle {
            display: block;
        }

    }
    @media (max-width: 768px) {
        .sm-remove{
            display:none;
        }
        /*.nav-wrapper{*/
            /*transform:translateX(-225px);*/
        /*}*/
        /*.nav-open .nav-wrapper{*/
            /*transform:translateX(0px);*/
            /*width: 100%;*/
        /*}*/

        /*.nav-open .content-container{*/
            /*transform:translateX(225px);*/
        /*}*/

        .nav-open .navigation .hide-small{
            opacity: 1;
        }
    }

    @media (max-width: 600px){
        .main-app-drawer-wrapper {
            z-index: 4;
        }
    }

    .rotate-icon {
        transform: rotate(30);
    }
</style>